import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import { Caption } from 'CHAMELEON_REACT_HUB';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`As a product team with mostly internal clients, we strive to have a perfect
product/market-fit. The better our product fits our customers (if you're reading
this, you're probably one of our customers!), the higher the willingness to use
is. And the more Chameleon is used, the higher Chameleon's effectiveness is.`}</p>
    <h2 {...{
      "id": "taking-stock",
      "style": {
        "position": "relative"
      }
    }}>{`Taking stock`}</h2>
    <p>{`For every new product, both design and development have always created bespoke,
tailor-made solutions. This practice created an unmanaged growth of different
implementations. With no apparent structure behind it, there was also no
overview of what exactly was implemented where.`}</p>
    <p>{`When we performed a UI audit, we found a few dozen similar hues of brand colors
or permutations of the same button. Multiply this by every piece of UI and many
years of development, and we realized how inconsistent, incomplete, and
difficult to maintain our workflow had become.`}</p>
    <p>{`This inventorization gives us a clear overview of all needed functionalities. It
also allows us to group elements that behaved almost identical so that we could
reduce these elements to a single item that covers nearly all use-cases.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d527b0897f6d200fd6dda9784954c3a1/bb3ba/ds-audit.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.857142857142858%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABOElEQVQY052PPUsCAQCG70e09Tf6Ba2NNjQ1BU0NLQ4RBEFTBA1NNWWLEFKCmFjmR552p3anx0l+nR+o5XckpnGnT1ERzb3T+y7PyyN02i0Mo0SxWKLRqNPpDXhu92HKvyKk0jlanT69bh3PpZeg10nHCKMaQXQxwFNOQ8+oFLQMYjSOpMjUUhKKIpF0O/Afb+NzHlBNa7zncwj6Y4WpNWM2GXLtCxHynTOoiSQrIdSgl0Ypi5JKkE0mufEHCEpRSrEwMfGWiOOQi/11XCdb5O9lJp+nQkLVcd/JhPQ45UKWblWlmRcZGGlGLwPGmoY5GmGZk2+n8RhM81fRfJv+nQgrG7vM2TZYOlplz77GmX2B0815PDuLyB4XxWUb5YRE09Dpvw4ZKQ/MjALWD0C/iqCnlK8+tSw+AN6vXcBHJzKEAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "DS Audit",
            "title": "DS Audit",
            "src": "/static/d527b0897f6d200fd6dda9784954c3a1/8c557/ds-audit.png",
            "srcSet": ["/static/d527b0897f6d200fd6dda9784954c3a1/4edbd/ds-audit.png 175w", "/static/d527b0897f6d200fd6dda9784954c3a1/13ae7/ds-audit.png 350w", "/static/d527b0897f6d200fd6dda9784954c3a1/8c557/ds-audit.png 700w", "/static/d527b0897f6d200fd6dda9784954c3a1/e996b/ds-audit.png 1050w", "/static/d527b0897f6d200fd6dda9784954c3a1/bb3ba/ds-audit.png 1121w"],
            "sizes": "(max-width: 700px) 100vw, 700px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <Caption textAlign="center" level={2} mdxType="Caption">
  Sample audit of De Standaard's main CSS file
    </Caption>
    <h2 {...{
      "id": "provide-ready-to-use-elements",
      "style": {
        "position": "relative"
      }
    }}>{`Provide ready-to-use elements`}</h2>
    <p>{`Both on the design-side as on the development-side, we provide designers and
developers with easy-to-use building blocks. These blocks are drop-in elements
that need little or no config to adapt themselves to a particular use-case.`}</p>
    <p>{`Design-wise, these are visual design languages and user interface libraries. We
decided to structure our system according to Atomic Design's methodology. A
modular design concept that has distinct stages and is ordered in a hierarchical
way to create interface design systems. By creating this collection of parts,
designers can re-use them across projects. This re-use ensures consistency and
allows a designer to focus on the more intricate and subtle changes that
attribute to top-notch user experiences.`}</p>
    <p>{`For developers, we provide React components that are tweakable by the props they
implement. Using these components, this ensures that all created products have a
streamlined look and feel. Another advantage is that this frees a developer so
that they can focus on more technical advancements as opposed to having to worry
about moving a button 2 pixels. Lastly, this ensures homogenous code-bases
across different product teams, contributing to higher frontend-wide knowledge.`}</p>
    <h2 {...{
      "id": "educating-designers-and-developers",
      "style": {
        "position": "relative"
      }
    }}>{`Educating designers and developers`}</h2>
    <p>{`To optimize the effectiveness of the communication between designers and
developers, they not only need to speak the same language; they also need to
employ the same mental model. If both worlds have the same way of thinking and
grasp the same abstract concepts, discussions can cut right to the core of the
underlying issue.`}</p>
    <p>{`We make sure that our React components follow the same mental model that a
designer uses when creating a new design. On the other hand, we are in constant
communication with designers so that they can better estimate the impact of
their design decisions. A simple design change may warrant a complex technical
implementation. The designer should then further evaluate if that change will
positively impact usability, or is nothing more than a nice-to-have.`}</p>
    <h2 {...{
      "id": "establishing-flows",
      "style": {
        "position": "relative"
      }
    }}>{`Establishing flows`}</h2>
    <p>{`Fully converting the whole flow from a waterfall model to an agile methodology
requires fundamental changes to the implementation. By working with both parties
and looking for bottlenecks, we can introduce new flows to accelerate product
delivery.`}</p>
    <p>{`Integrating new tools in the chain to improve cross-domain communication will
allow both parties to influence each other to improve the overall quality.`}</p>
    <p>{`By involving developers earlier in the flow, they can help shape the flow with
added technical insights. On the other hand, developers should also include
designers in their implementation phases to help fine-tune projects right before
they are deployed to production.`}</p>
    <p>{`Communication is vital; that's a given. But by providing a clear framework in
which this communication can happen, will improve the willingness to
participate.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      